#root {
    width: inherit !important;
}

#Recording,
#callflow {
    background-color: #f5f5f5;
}

#Recording {
    background-color: #f5f5f5;
}

#callflow-list,
#callflow {
    padding: 25px;
}

#callflow-list h5 {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    margin-bottom: 10px;
    color: #6264a7;
    font-family: inherit;
    font-size: medium;
    font-weight: 400;
}

#callflow-list h2 {
    margin-bottom: 25px;
    font-size: x-large;
    font-weight: 400;
    font-family: system-ui;
}

#callflow-list h5:hover {
    cursor: pointer;
}

#callflow h2 {
    margin-bottom: 25px;
    font-size: x-large;
    font-weight: 400;
    font-family: system-ui;
}



#callflow table>tr>th:nth-child(2) {
    min-width: 200px !important;
}

.call-name h5 {
    color: #6264a7;
    font-family: inherit;
    font-size: medium;
    font-weight: 400;
}

.call-details {
    display: flex;
    margin-bottom: 10px;
}

#login-navigation {
    background-color: #dadae3;
    display: flex;
    justify-content: flex-end;
}

#menu-callflow {
    display: flex;
}

#menu-callflow h2 {
    width: 49%;
}

.rhap_container svg {
    width: 70% !important;
}

.rhap_container {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0px 10px !important;
    min-width: 450px !important;
}

.sr-only {
    display: none;
}

#callflow table {
    min-width: 130% !important;
    overflow-x: scroll !important;
}

.react-bootstrap-table {
    text-overflow: ellipsis !important;
    font-weight: normal !important;
    font-size: 12px !important;
}

.rhap_time {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-weight: normal !important;
    font-size: 12px !important;
}

/*
.rhap_horizontal-reverse{
    flex-direction: inherit!important;
}
*/
.filter {
    margin-left: 7px !important;
    display: inline-flex !important;
}

.react-bootstrap-table th div {
    /* max-width: 110px;*/
    vertical-align: top !important;
}

.react-bootstrap-table th label {
    vertical-align: top !important;
}

.table>:not(:last-child)>:last-child>* {
    vertical-align: baseline !important;
}

.react-bootstrap-table th input,
.react-bootstrap-table th select {
    box-shadow: none !important;
    border-radius: 0px !important;
}

.react-bootstrap-table th input:active,
.react-bootstrap-table th select:active {
    border: 1px solid #ced4da !important;
}

.form-control {
    font-size: 12px !important;
}

.rhap_additional-controls {
    display: none !important;
}

.page-item.active .page-link {
    background-color: #494b83;
    border-color: #494b83;
    color: white !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.page-item .page-link {
    color: black;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.table td {
    padding-top: 0px;
    padding-bottom: 0px;
}

th,
td,
td {
    border-top: none !important;
    border-left: none !important;
    border-bottom: none !important;
}

.rhap_progress-indicator {
    top: -4px !important;
    width: 12px !important;
    height: 12px !important;
}

#filter-date input,
#filter-duration input {
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 12px !important;
    margin-left: 7px !important;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
    border: 1px solid #ced4da !important;
}

#filter-date input:focus-visible {
    border: 1px solid blue !important;
}

#filter-date input:focus {
    border: 1px solid blue !important;
}

.table>:not(caption)>*>* {
    vertical-align: middle !important;
}

.filter-icon {
    margin-left: 10px;
}

.filter-icon:hover {
    cursor: pointer;
}

.play-btn {
    font-size: 30px;
    margin-left: 40%;
    color: #494b83;
    cursor: pointer;
}

.back-btn button,
.back-btn h2 {
    margin-bottom: auto;
    margin-top: auto;
}

.back-btn button {
    margin-right: 20px;
}

#filter-duration {
    width: 240px;
}

.table>:not(:last-child)>:last-child>* {
    width: 240px;
}